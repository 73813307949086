import React from "react";

/*

Passare nelle props:

1) stile, come in questo esempio:
    stile={{border:"1px solid #C0C0C0",fontSize:16,backgroundColor:"#F0F0F0"}}

2) elencoCampi come in questo esempio:
    elencoCampi = [
        { label: 'Input 1', type: 'text', initialWidth: 200, minWidth: 150 },
        { label: 'Input 2', type: 'checkbox', initialWidth: 0, minWidth: 0 },
        { label: 'Input 3', type: 'select', options: ['Option 1', 'Option 2', 'Option 3'], initialWidth: 250, minWidth: 200 },
        // Aggiungi altri inputConfig secondo le tue esigenze
    ];

3) valoriCampiForm e setValoriCampiForm che sono la variabile di stato e la sua funzione di set, così definita inizialmente:
    const [valoriCampiForm, setValoriCampiForm] = useState({})

    e che poi a regime conterrà "input<n>:" <valore del campo input_n con n che va da zero a numero di campi meno 1.

*/

function FormResponsive(props) {    

    // Funzione per aggiornare i valori dei campi con quanto scrive l'utente
    function aggiornaValoriCampi(nomeCampo, valore) {
        var n = {
            ...props.valoriCampiForm,
            [nomeCampo]: valore,
        }
        props.setValoriCampiForm(n);
    };

    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
    };

    const inputContainers = props.elencoCampi.map((input, index) => {
        const containerStyle = {
            display: 'flex',
            alignItems: 'center', // Allinea label e input verticalmente
            flexGrow: 1,
            marginTop: '5px',
            marginBottom: '5px',
            marginRight: '15px',
            flexDirection: input.type === 'text' ? 'row' : 'row-reverse', // Inverti l'ordine per le checkbox
        };
    
        const labelStyle = {
            minWidth: '0px', // Larghezza minima per la label
            marginRight: input.type === 'text' || input.type === 'select' ? '10px' : '0', // Aggiunge spazio tra label e input per le input di tipo text
            marginLeft: input.type === 'text' || input.type === 'select' ? '0' : '10px', // Aggiunge spazio tra quadratino e label per le checkbox e le select
            display: 'flex', // Mostra sempre la label vicino al rispettivo elemento
            alignItems: 'center', // Allinea la label e l'input verticalmente
            flex: input.type === 'checkbox' ? '1' : 'unset', // Utilizza tutto lo spazio disponibile per la label delle checkbox
        };
    
        const inputStyle = {
            ...props.stile,
            flexGrow: input.type === 'text' || input.type === 'select' ? 1 : 0, // Utilizza tutto lo spazio disponibile per la select
            width: input.type === 'text' ? `${input.initialWidth}px` : 'auto',
            minWidth: input.type === 'text' || input.type === 'select' ? `${input.minWidth}px` : 'auto',
            padding: '1px', // dimensione delle input box e delle tendine
            boxSizing: 'border-box',
        };
    
        const selectStyle = {
            ...inputStyle,
            ...props.stile,
            appearance: 'none', // Nasconde l'aspetto nativo del menu a tendina
            
        };

        const inputElement = input.type === 'text' ? (
            <input type="text" style={inputStyle} value={props.valoriCampiForm[`input_${index}`]} onChange={e => aggiornaValoriCampi(`input_${index}`, e.target.value)}/>
        ) : input.type === 'select' ? (
            <select style={selectStyle} onChange={e => aggiornaValoriCampi(`input_${index}`, e.target.value)}>
            {input.options.map((option, index) => (
                <option key={index} value={option}>
                {option}
                </option>
            ))}
            </select>
        ) : (
            <input 
                type="checkbox" 
                style={inputStyle} 
                checked={props.valoriCampiForm[`input_${index}`]==1 || false}
                onChange={e => aggiornaValoriCampi(`input_${index}`, e.target.checked?1:0)}/>
        );

        return (
            <div key={index} style={containerStyle}>
            {(input.type === 'text' || input.type === 'checkbox') && <label style={labelStyle}>{input.label}</label>}
            {inputElement}
            {input.type === 'select' && <label style={labelStyle}>{input.label}</label>}
            </div>
        );
    });

    return (
        <div style={containerStyle}>
            {inputContainers}
        </div>
    );
}

export default FormResponsive;