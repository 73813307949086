import React from 'react';
import FadeLoader from "react-spinners/FadeLoader";

function Clessidra(props) {        
    return (
        <div style={{display:'flex', flexDirection:'row', marginTop:props.top}}>
            <div style={{flex:1}}/>
            <FadeLoader color={"#000000"} loading={props.loading} aria-label="Loading Spinner" data-testid="loader"/>
            <div style={{flex:1}}/>
        </div>
   );
}
export default Clessidra;
