
import React from "react";

function TitoloPagina(props) {
    var testo="";
    switch (props.pagina) {
        case "eventi":
            testo="Eventi";
        break;
        case "prenotazioni":
            testo="Prenotazioni corsi ed attrezzature";
        break;
        case "allenamenti":
            testo="Allenamenti";
        break;
        case "gestioneEconomica":
            testo="Gestione Economica";
        break;
        case "scadenze":
            testo="Scadenze";
        break;
        case "news":
            testo="News";
        break;
        case "report":
            testo="Report";
        break;
        case "app":
            testo="APP";
        break;
        case "anagrafiche":
            testo="Anagrafiche";
        break;
        case "sicurezza":
            testo="Sicurezza";
        break;
        case "personalizzazioni":
            testo="Personalizzazioni";
        break;
        case "chiSiamo":
            testo="Chi siamo";
        break;
        case "privacy":
            testo="Privacy e cookies";
        break;
        case "condizioni":
            testo="Termini e condizioni";
        break;
        case "acquista":
            testo="Acquista";
        break;
        case "provaGratis":
            testo="Prova Gratis Senza Impegno";
        break;
        case "contatti":
            testo="Contatti";
        break;
        case "prezzi":
            testo="Prezzi";
        break;
    }
    return (
        <div style={{height:50, color:"#505050", fontSize:30, fontWeight:"bold"}}>{testo}</div>
    )
}

export default TitoloPagina;