import React from "react";
import logoTitolo from './immagini/logoTitolo.jpeg';

function Intestazione(props) {        
    
    return (
        <>
            <div style={{minHeight:120, paddingLeft:20, paddingRight:20}}>
                <h1>
                    <img src={logoTitolo} alt="Gestione Sportiva" height={120}/>
                    <span style={{fontSize:16, color:"#FFFFFF", backgroundColor:"#ff9900", paddingLeft:10, paddingRight:10, paddingBottom:1, whiteSpace: "nowrap"}}>Web & App</span>
                </h1>
            </div>      
            <div style={{minHeight:50, color:"#909090", paddingLeft:20, paddingRight:20}}>
                <h2>
                    Gestione semplice ed efficace
                    {props.dimensioniApp.flagPaginaOrizzontale ? " " : <br/>}
                    della tua società, associazione o palestra
                </h2>
            </div>            
      </>
    )
}

export default Intestazione;