import React, {useEffect, useState} from "react";

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'

import appIcona from './immagini/appIcona.png';

import './App.css';

function Prezzi(props) {

    function Nota(props) {
        return (<sup><span style={{fontSize:11}}>({props.numero})</span></sup>);
    }

    return (
        <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>

            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    fontSize:16,
                    lineHeight:1.4                
                }}
            >
                <b>Scarica la App:</b>
                <br/>
                La App è a disposizione gratuitamente per i clienti delle Società che utilizzano Gestione Sportiva.
                <div style={{display:'flex', flexDirection:'row', width:'95%', marginTop:20, marginBottom:20}}>

                    <div style={{flex:1, backgroundColor:'#C8EA5D', border: '1px solid black', paddingBottom:10}}
                    onClick={() => window.location.href="https://play.google.com/store/apps/details?id=com.gestionesportiva&hl=it_IT"}>
                        <span style={{fontSize:22}}>ANDROID</span>
                        <br/>
                        <img src={appIcona} alt="QRCode" height={60} />
                        <br/>
                        Clicca qui per scaricare la app<br/>da Google Play<br/>
                        <br/>
                        <span style={{fontSize:12}}>oppure cerca "com.gestionesportiva"<br/>su Google Play</span>
                    </div>


                    <div style={{flex:1, backgroundColor:'#a5d9fd', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', paddingBottom:10}}
                    onClick={() => window.location.href="https://apps.apple.com/it/app/gestione-sportiva/id6484272541"}>
                    <span style={{fontSize:22}}>APPLE</span>
                    <br/>
                        <img src={appIcona} alt="QRCode" height={60} />
                        <br/>
                        Clicca qui per scaricare la app<br/>da Apple App Store<br/>
                        <br/>
                        <span style={{fontSize:12}}>oppure cerca "gestione sportiva"<br/>su Apple App Store </span>
                    </div>
                </div>
            </div>

            <FondoPagina {...props}/>
        </div>
    )        
}

export default Prezzi;