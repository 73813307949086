import React from "react";

function ElementoInput(props) {
    if (props.dimensioniApp.flagPaginaOrizzontale) {
        return (
            <div style={{flex:1, display:"flex",flexDirection:"row", marginTop:10}}>
                <div>
                    {props.etichetta}
                </div>
                <div style={{width:10}}/>
                <div style={{flex:1}}>
                <input 
                    style={{border:"1px solid #C0C0C0", fontSize:16, backgroundColor:"#F0F0F0", width:"100%"}} 
                    value={props.valore}                        
                    onChange={(e) => props.callback(e.target.value)}
                />
                </div>
                <div style={{width:20}}/>
            </div>
        )
    }

    // pagina verticale
    return (
        <div style={{display:"flex",flexDirection:"column", marginTop:10, marginRight:20}}>
            <div>
                {props.etichetta}
            </div>
            <div style={{flex:1}}>
                <input 
                    style={{border:"1px solid #C0C0C0", fontSize:16, backgroundColor:"#F0F0F0", width:"100%"}}
                    value={props.valore}
                    onChange={(e) => props.callback(e.target.value)}
                />
            </div>
        </div>
    )         
}

export default ElementoInput;