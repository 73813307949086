//import { NumericFormat } from 'react-number-format';


function FormattaImportoVideo(importo,lasciaVuoto = 0) {

    let importoOutput='';
    //da formato 23.45689 a 23,46 quindi 2 cifre decimali e virgola in luogo del punto
   

    if(importo){
        //importo = parseFloat(importo);
        //console.log("importo"+importo);
        if (typeof importo === 'string'){
        
            if(importo.includes(',')){
             
                var num_parts = importo.toString().split(",");
                num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                importo = num_parts[0]+","+num_parts[1];
       
                return importo;//scommentare !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                
                //return <NumericFormat displayType={'text'} type="number" value={importo} thousandSeparator="." decimalSeparator="," />;
            } 
        }
       
        importoOutput = parseFloat(importo).toFixed(2).replace(".", ",");
        //console.log("calcolo"+importoOutput);
    }else{
        if(lasciaVuoto === 1) return "";
        else importoOutput = "0,00";
    }   

    var num_parts = importoOutput.toString().split(",");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    importoOutput = num_parts[0]+","+num_parts[1];

    //console.log("ritorno"+importoOutput);
    return importoOutput;
    //return <NumericFormat displayType={'text'} type="number" value={importoOutput} thousandSeparator="." decimalSeparator="," />;
}

export default FormattaImportoVideo;
