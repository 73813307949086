import React, {useEffect, useState} from "react";

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'

import './App.css';

function Prezzi(props) {

    function Nota(props) {
        return (<sup><span style={{fontSize:11}}>({props.numero})</span></sup>);
    }

    return (
        <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>
                        
            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    fontSize:props.dimensioniApp.x>600?16:15,
                    lineHeight:1.4                
                }}
            >
                <b>I piani standard sono:</b>

                {props.dimensioniApp.x>600 ?

                <div style={{display:'flex', flexDirection:'row', width:'95%', marginTop:20, marginBottom:20}}>

                    <div style={{flex:1, backgroundColor:'#70FF90', border: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO VERDE</span>
                        <br/><br/>
                        fino a 300 iscritti <Nota numero={1}/><br/>
                        fino a 600 Mbytes<br/><br/>
                        <span style={{fontSize:30}}>14,58</span> euro/mese <Nota numero={2}/><Nota numero={4}/>

                    </div>


                    <div style={{flex:1, backgroundColor:'#FFFFA0', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO GIALLO</span>
                        <br/><br/>
                        fino a 500 iscritti <Nota numero={1}/><br/>
                        fino a 1000 Mbytes<br/><br/>
                        <span style={{fontSize:30}}>22,50</span> euro/mese <Nota numero={2}/><Nota numero={4}/>
                    </div>
 

                    <div style={{flex:1, backgroundColor:'#B5E9FF', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO AZZURRO</span>
                        <br/><br/>
                        fino a 1000 iscritti <Nota numero={1}/><br/>
                        fino a 2000 Mbytes<br/><br/>
                        <span style={{fontSize:30}}>37,50</span> euro/mese <Nota numero={2}/><Nota numero={4}/>
                    </div>                    

                </div>
                :
                <>
                <div style={{display:'flex', flexDirection:'row', width:'95%', marginTop:20, marginBottom:20}}>

                    <div style={{flex:1, backgroundColor:'#70FF90', border: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO VERDE</span>
                        <br/><br/>
                        fino a 300 iscritti <Nota numero={1}/><br/>
                        fino a 600 Mbytes<br/><br/>
                        <span style={{fontSize:30}}>14,58</span> euro/mese <Nota numero={2}/><Nota numero={4}/>

                    </div>


                    <div style={{flex:1, backgroundColor:'#FFFFA0', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO GIALLO</span>
                        <br/><br/>
                        fino a 500 iscritti <Nota numero={1}/><br/>
                        fino a 1000 Mbytes<br/><br/>
                        <span style={{fontSize:30}}>22,50</span> euro/mese <Nota numero={2}/><Nota numero={4}/>
                    </div>

                    </div>
                    <div style={{display:'flex', flexDirection:'row', width:'95%', marginTop:0, marginBottom:20}}>

                    <div style={{flex:1, backgroundColor:'#B5E9FF', border: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO AZZURRO</span>
                        <br/><br/>
                        fino a 1000 iscritti <Nota numero={1}/><br/>
                        fino a 2000 Mbytes<br/><br/>
                        <span style={{fontSize:30}}>37,50</span> euro/mese <Nota numero={2}/><Nota numero={4}/>
                    </div>                    

                </div>
                </>
                }

                <b>Sono anche disponibili:</b>

                {props.dimensioniApp.x>600 ?

                <div style={{display:'flex', flexDirection:'row', width:'95%', marginTop:20, marginBottom:20}}>
                    <div style={{flex:1, backgroundColor:'#F0F0F0', border: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>ESTENSIONE</span>
                        <br/><br/>
                        300 iscritti aggiuntivi <Nota numero={1}/><br/>
                        600 Mbytes aggiuntivi<br/><br/>
                        <span style={{fontSize:30}}>14,58</span> euro/mese <Nota numero={3}/><Nota numero={4}/>
                    </div>
                    <div style={{flex:1, backgroundColor:'#FFD0D0', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO PERSONALIZZATO</span>
                        <br/><br/>
                        oltre 1000 iscritti <Nota numero={1}/><br/>
                        oltre 2000 Mbytes<br/><br/>
                        <span style={{fontSize:18}}>Contattaci per una quotazione su misura per te:</span><br/>
                        <a href= "mailto:info@gestionesportiva.it">info@gestionesportiva.it</a>
                    </div>
                    <div style={{flex:1, backgroundColor:'#F0F0F0', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>CONTROLLO ACCESSI</span>
                        <br/><br/>
                        integrazione ai sistemi di lettura tessere e azionamento tornelli<br/>
                        funzionamento con tessere RFID, tessere QRCode o APP QRCode<br/><br/>
                        <span style={{fontSize:18}}>Contattaci per una quotazione su misura per te:</span><br/>
                        <a href= "mailto:info@gestionesportiva.it">info@gestionesportiva.it</a>
                    </div>

                </div>
                :
                <>
                <div style={{display:'flex', flexDirection:'row', width:'95%', marginTop:20, marginBottom:20}}>
                    <div style={{flex:1, backgroundColor:'#F0F0F0', border: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>ESTENSIONE</span>
                        <br/><br/>
                        300 iscritti aggiuntivi <Nota numero={1}/><br/>
                        600 Mbytes aggiuntivi<br/><br/>
                        <span style={{fontSize:30}}>14,58</span> euro/mese <Nota numero={3}/><Nota numero={4}/>
                    </div>
                    <div style={{flex:1, backgroundColor:'#FFD0D0', borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>PIANO PERSONALIZZATO</span>
                        <br/><br/>
                        oltre 1000 iscritti <Nota numero={1}/><br/>
                        oltre 2000 Mbytes<br/><br/>
                        <span style={{fontSize:16}}>Contattaci per una quotazione su misura per te:</span><br/>
                        <a href= "mailto:info@gestionesportiva.it">info@gestionesportiva.it</a>
                    </div>

                    </div>
                    <div style={{display:'flex', flexDirection:'row', width:'95%', marginTop:0}}>

                    <div style={{flex:1, backgroundColor:'#F0F0F0', border: '1px solid black', paddingBottom:10}}>
                        <span style={{fontSize:22}}>CONTROLLO ACCESSI</span>
                        <br/><br/>
                        integrazione ai sistemi di lettura tessere e azionamento tornelli<br/>
                        funzionamento con tessere RFID, tessere QRCode o APP QRCode<br/><br/>
                        <span style={{fontSize:16}}>Contattaci per una quotazione su misura per te:</span><br/>
                        <a href= "mailto:info@gestionesportiva.it">info@gestionesportiva.it</a>
                    </div>

                </div>
                </>
                }


                <br/><b>Gestione Sportiva comprende sempre:</b><br/>
                <div>
                tutte le funzioni dell'applicazione tranne il Controllo Accessi<Nota numero={5}/>,<br/>
                accesso alla web app senza limitazione al numero di operatori <Nota numero={6}/>,<br/>
                utilizzo della app per iOS e Android per tutti i tuoi clienti<Nota numero={7}/><br/>                
                </div>

                <br/><b>Metodi di pagamento:</b><br/>
                <div>
                PayPal,<br/>
                carta di credito (con transazione sicura gestita da PayPal),<br/>
                bonifico bancario<br/>
                </div>

            </div>
            
            <div style={{textAlign:'left', paddingLeft:20, paddingRight:20, paddingBottom:30}}>
                Note<br/>
                <div style={{borderBottom: "1px solid #C0C0C0", width:"100%"}}></div>
                <br/>
                <span style={{fontSize:14}}>
                (1) Gli iscritti che hanno lasciato la società ma vengono mantenuti negli elenchi per motivi di reportistica, o per eventualmente riattivarli in futuro, <b>contano ciascuno come metà iscritto</b>.<br/>
                (2) Per i piani, è richiesto il pagamento anticipato di 12 mesi in un'unica soluzione.<br/>
                (3) Per le estensioni, è richiesto il pagamento anticipato in un'unica soluzione di un numero di mesi pari al numero di mesi necessari per arrivare alla fine del piano sottoscritto, arrotondato per eccesso. L'estensione può essere acquistata una sola volta nell'anno.<br/>
                (4) <b>Tutti i prezzi sono IVA INCLUSA</b>.<br/>
                (5) Il Controllo Accessi è un modulo da personalizzare a seconda del tipo di lettore, della struttura e delle funzioni che vuoi. Contattaci per un preventivo senza impegno.<br/>
                (6) Il numero massimo di operatori coincide con il numero massimo di iscritti.<br/>
                (7) APP verificata sui dispositivi aggiornati di maggior diffusione.<br/>                
                </span>
            </div>

            <FondoPagina {...props}/>
        </div>
    )        
}

export default Prezzi;