import React from "react";
import {useNavigate} from 'react-router-dom';

function MenuAlto(props) {
    const comandoNavigazione = useNavigate();

    function vaiLink(link) {
      comandoNavigazione(link);
      window.scrollTo(0, 0);
    }

    function VoceMenu(props) {
        if (props.enfasi==1) {
          return (
            <div 
              style={{
                paddingLeft:20, 
                paddingRight: 20, 
                marginLeft:20, 
                marginTop:5, 
                marginBottom:5, 
                paddingTop:4, 
                paddingBottom:4,
                fontSize:18, 
                backgroundColor:"#FFFFFF", 
                color: "#000000",  
                cursor:"pointer", 
                whiteSpace: "nowrap"
              }}
              onClick={() => window.location.href = props.urlServizio}
            >
              {props.voce}
            </div>
          )  
        }
    
        return (
          <div style={{paddingLeft:20, paddingRight: 20, paddingTop:10, cursor:"pointer", whiteSpace: "nowrap"}}
            onClick={() => {vaiLink(props.link)}}
          >
            {props.voce}
          </div>
        )
    }
    
    return (<div style={{width: "100%", minHeight: 40, backgroundColor:"#505050", color: "#F0F0F0", fontSize:14, display:'flex', flexWrap: "wrap", justifyContent:'center' }}>
      {props.bottoneHome && <VoceMenu {...props} voce="Home" link="/"/>}
      <VoceMenu {...props} voce="Scarica APP" link="/scaricaApp"/>
      <VoceMenu {...props} voce="Acquista" link="/acquista"/>
      <VoceMenu {...props} voce="Prezzi" link="/prezzi"/>
      <VoceMenu {...props} voce="Contatti" link="/contatti"/>
      <VoceMenu {...props} voce="Accedi" enfasi={1}/>
    </div>)
}

export default MenuAlto;