import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import PaginaPrincipale from './PaginaPrincipale';
import Parametri from './parametri';
const packageJson = require('../package.json');

const root = ReactDOM.createRoot(document.getElementById('root'));

const urlMultimedia = "https://gestionesportiva.it/multimedia/";
const urlServizio = "https://app.gestionesportiva.it";
const emailAssistenza = "assistenza@gestionesportiva.it";

/* errorElement: <ErrorPage  urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>, */

const router = createBrowserRouter([
  {
    path: "/",
    element: <PaginaPrincipale pagina="home" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
    errorElement: <PaginaPrincipale pagina="home" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/eventi",
    element: <PaginaPrincipale pagina="eventi" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/prenotazioni",
    element: <PaginaPrincipale pagina="prenotazioni" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/allenamenti",
    element: <PaginaPrincipale pagina="allenamenti" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/gestioneEconomica",
    element: <PaginaPrincipale pagina="gestioneEconomica" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/scadenze",
    element: <PaginaPrincipale pagina="scadenze" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/news",
    element: <PaginaPrincipale pagina="news" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/report",
    element: <PaginaPrincipale pagina="report" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/app",
    element: <PaginaPrincipale pagina="app" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/anagrafiche",
    element: <PaginaPrincipale pagina="anagrafiche" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/qrcode",
    element: <PaginaPrincipale pagina="qrcode" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },  
  {
    path: "/sicurezza",
    element: <PaginaPrincipale pagina="sicurezza" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/estrazione",
    element: <PaginaPrincipale pagina="estrazione" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/personalizzazioni",
    element: <PaginaPrincipale pagina="personalizzazioni" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/chiSiamo",
    element: <PaginaPrincipale pagina="chiSiamo" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/privacy",
    element: <PaginaPrincipale pagina="privacy" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/acquista",
    element: <PaginaPrincipale pagina="acquista" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/provaGratis",
    element: <PaginaPrincipale pagina="provaGratis" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/privacy",
    element: <PaginaPrincipale pagina="privacy" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/condizioni",
    element: <PaginaPrincipale pagina="condizioni" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/contatti",
    element: <PaginaPrincipale pagina="contatti" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/prezzi",
    element: <PaginaPrincipale pagina="prezzi" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },
  {
    path: "/scaricaApp",
    element: <PaginaPrincipale pagina="scaricaApp" urlMultimedia={urlMultimedia} urlServizio={urlServizio} emailAssistenza={emailAssistenza}/>,
  },  
]);

const versioneCorrenteNonAggiornata = (ultimaVersione, versioneCorrente) => {
  const versionsA = ultimaVersione.split(/\./g);

  const versionsB = versioneCorrente.split(/\./g);
  while (versionsA.length || versionsB.length) {
  const a = Number(versionsA.shift());

  const b = Number(versionsB.shift());
  // eslint-disable-next-line no-continue
  if (a === b) continue;
  // eslint-disable-next-line no-restricted-globals
  return a > b || isNaN(b);
  }
  return false;
};

function ControlloVersione() {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"versione"})
  };

  fetch(Parametri("serverURL"), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {          
          if(valoreDiRitorno.risultatoOperazione===1){
              alert("controlloVersione: back="+valoreDiRitorno.versione+" package="+packageJson.version);
              if (versioneCorrenteNonAggiornata(valoreDiRitorno.versione, packageJson.version)) {
                  window.location.reload(true);
              }
          }
      },
      (error) => {window.location.reload(true);}
  ); 
}

// Non mettere <React.StrictMode> perché quello fa ricaricare 2 volte tutti i componenti interni, quindi tutte le pagine!

root.render(
  <>
    <RouterProvider router={router} />       
  </>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
