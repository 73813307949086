import React, {useEffect, useState} from "react";

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'

import immagineA from "./immagini/a.png";
import immagineI from "./immagini/i.png";
import immagineG from "./immagini/g.png";
import immagineSopra from "./immagini/sopra.png";
import immagineSotto from "./immagini/sotto.png";
import scritteDestra from "./immagini/scritteDestra.png";

import './App.css';
import 'animate.css';

function ChiSiamo(props) {
    return (
        <div className='App'>            
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>

            <div class="contenitore-superiore">
                <div class="animate__animated animate__fadeIn" style={{marginTop:20}}>
                <img style={{height: 204, width: 191}} src={immagineI} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInDown animate__slow" style={{marginTop:-207}}>
                <img style={{height: 204, width: 191}} src={immagineSopra} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInUp animate__slow" style={{marginTop:-207}}>
                <img style={{height: 204, width: 191}} src={immagineSotto} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInLeft animate__slow" style={{marginTop:-207}}>
                <img style={{height: 204, width: 191}} src={immagineA} alt="AIG"/>
                </div>
                <div class="animate__animated animate__slideInRight animate__slow" style={{marginTop:-207}}>
                <img style={{height: 204, width: 191}} src={immagineG} alt="AIG"/>
                </div>
                {props.dimensioniApp.x>700 ?
                <div style={{marginTop:-182, marginLeft: 400, marginBottom:40}}>
                    <img src={scritteDestra} alt="AIG"/>
                </div>
                :
                <div style={{color:"#909090", fontWeight:"bold"}}>
                    Azienda Informatica Genova
                </div>
                }
            </div>


            Gestione Sportiva è un prodotto <a href='https://aigenova.it'>AIG - Azienda Informatica Genova</a>, che nasce dall'esperienza pluridecennale di sviluppo software ad alto livello per primarie aziende italiane
            nei settori dei servizi e della produzione.<br/>
            AIG è orientata allo sviluppo di servizi web e APP per iOS e Android, personalizzati sulle esigenze dei Clienti, per aziende di piccole e grandi dimensioni.
            <br/>
            In questo scenario, nasce il prodotto Gestione Sportiva, volto a fornire uno strumento semplice, completo e in continua evoluzione per il mondo dello Sport e delle Associazioni Sportive.
            <br/><br/><br/>
            <FondoPagina {...props}/>
        </div>
    )
}

export default ChiSiamo;