function Parametri(parametro) {
    // importanti gli spazi nella riga seguente e l'assenza del punto e virgola, per lo script che aggiorna il numero di versione
const versione = 6

    switch (parametro) {
        case 'serverURL': return 'https://ema1.gestionesportiva.it/link_back_front_sito.php'; break;
        case 'release': return "P-"+versione; break;
    }
    return "";
}

export default Parametri;
